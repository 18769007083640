
import { firestore } from "@/firebase";
import { ROOT_ACTIONS } from "@/store/actions";
import { format } from "date-fns";
import {
  collection,
  getDoc,
  getDocs,
  query,
  where,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Home extends Vue {
  rows = [] as any[];
  headers = [
    { text: "Naam", value: "name" },
    { text: "Startdatum", value: "startDate" },
    { text: "Status", value: "status" },
    { text: "Fase 1", value: "phase1" },
    { text: "Fase 2", value: "phase2" },
    { text: "Fase 3", value: "phase3" },
    { text: "Uren Tot.", value: "totalHours" },
    { text: "Aanwzg", value: "present" },
    { text: "Bevestigd", value: "confirmed" },
    { text: "Niet Bev.", value: "notConfirmed" },
    { text: "Coach", value: "coach" },
  ];

  loading = true;

  async mounted() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, []);

    // Fetch everything in bulk
    const programsQuery = query(
      collection(firestore, "programs"),
      where("isArchived", "!=", true)
    );
    const programsSnapshot = await getDocs(programsQuery);

    const participantsSnapshot = await getDocs(collection(firestore, "users"));
    const coachesSnapshot = await getDocs(collection(firestore, "users"));

    const phasesQuery = query(collection(firestore, "phases"));
    const phasesSnapshot = await getDocs(phasesQuery);

    const activitiesQuery = query(collection(firestore, "activities"));
    const activitiesSnapshot = await getDocs(activitiesQuery);

    const actualActivitiesQuery = query(
      collection(firestore, "actual-activities")
    );
    const actualActivitiesSnapshot = await getDocs(actualActivitiesQuery);

    // Index data for faster lookups
    const participantsMap = new Map();
    participantsSnapshot.docs.forEach((doc) =>
      participantsMap.set(doc.id, doc.data())
    );

    const coachesMap = new Map();
    coachesSnapshot.docs.forEach((doc) => coachesMap.set(doc.id, doc.data()));

    const phasesMap = new Map();
    phasesSnapshot.docs.forEach((doc) => {
      const phaseData = doc.data();
      if (!phasesMap.has(phaseData.programId)) {
        phasesMap.set(phaseData.programId, []);
      }
      phasesMap.get(phaseData.programId).push({ ...phaseData, id: doc.id });
    });

    const activitiesMap = new Map();
    activitiesSnapshot.docs.forEach((doc) => {
      const activityData = doc.data();
      if (!activitiesMap.has(activityData.phaseId)) {
        activitiesMap.set(activityData.phaseId, []);
      }
      activitiesMap
        .get(activityData.phaseId)
        .push({ ...activityData, id: doc.id });
    });

    const actualActivitiesMap = new Map();
    actualActivitiesSnapshot.docs.forEach((doc) => {
      const actualActivityData = doc.data();
      if (!actualActivitiesMap.has(actualActivityData.activityId)) {
        actualActivitiesMap.set(actualActivityData.activityId, []);
      }
      actualActivitiesMap
        .get(actualActivityData.activityId)
        .push({ ...actualActivityData, id: doc.id });
    });

    // Process programs
    for (const programDoc of programsSnapshot.docs) {
      const programData = programDoc.data();
      const participant = participantsMap.get(programData.participant);
      const coach = coachesMap.get(programData.coach);

      if (!participant || !coach) {
        continue; // Skip if participant or coach doesn't exist
      }

      const programPhases = phasesMap.get(programDoc.id) || [];
      programPhases.sort((a: any, b: any) => a.start.toDate() - b.start.toDate());

      const [firstPhase, secondPhase, thirdPhase] = programPhases;

      if (!firstPhase) {
        continue;
      }

      let currentPhase = "Einde traject";
      const now = new Date();

      if (firstPhase.start.toDate() < now && firstPhase.end.toDate() > now) {
        currentPhase = "Fase 1";
      } else if (
        secondPhase?.start.toDate() < now &&
        secondPhase?.end.toDate() > now
      ) {
        currentPhase = "Fase 2";
      } else if (
        thirdPhase?.start.toDate() < now &&
        thirdPhase?.end.toDate() > now
      ) {
        currentPhase = "Fase 3";
      }

      const phasesToCheck = [firstPhase, secondPhase, thirdPhase].filter(
        Boolean
      );
      const hoursPhases = Array(phasesToCheck.length).fill(0);
      const actualHoursPhases = Array(phasesToCheck.length).fill(0);
      const confirmedHoursPhases = Array(phasesToCheck.length).fill(0);
      const nonConfirmedHoursPhases = Array(phasesToCheck.length).fill(0);
      const phaseWarnings = Array(phasesToCheck.length).fill(false);

      for (const [index, phase] of phasesToCheck.entries()) {
        const activitiesForPhase = activitiesMap.get(phase.id) || [];
        const activitiesPlanned = activitiesForPhase.reduce(
          (acc: any, curr: any) => acc + curr.plannedHours,
          0
        );

        hoursPhases[index] = activitiesPlanned;

        for (const activity of activitiesForPhase) {
          const actualActivities = actualActivitiesMap.get(activity.id) || [];
          const presentHours = actualActivities
            .filter((e: any) => e.participantPresent)
            .reduce((acc: any, curr: any) => acc + curr.actualHours, 0);

          const confirmedHours = actualActivities
            .filter((e: any) => e.participantConfirmed)
            .reduce((acc: any, curr: any) => acc + curr.actualHours, 0);

          actualHoursPhases[index] += presentHours;
          confirmedHoursPhases[index] += confirmedHours;
          nonConfirmedHoursPhases[index] += presentHours - confirmedHours;

          if (
            activitiesPlanned * 1.2 < presentHours &&
            phase.end.toDate() < now
          ) {
            phaseWarnings[index] = true;
          }
        }
      }

      const totalConfirmedHours = confirmedHoursPhases.reduce(
        (acc, curr) => acc + curr,
        0
      );

      const totalNonConfirmedHours = nonConfirmedHoursPhases.reduce(
        (acc, curr) => acc + curr,
        0
      );

      const totalPresentHours = actualHoursPhases.reduce(
        (acc, curr) => acc + curr,
        0
      );

      //   warning if 20% or more hours are not confirmed
      const warning = totalNonConfirmedHours / totalPresentHours >= 0.2;

      this.rows.push({
        programId: programDoc.id,
        name: `${participant.firstname} ${participant.lastname}`,
        startDate: format(firstPhase.start.toDate(), "dd/MM/yyyy"),
        status: currentPhase,
        phase1: hoursPhases[0],
        phase2: hoursPhases[1],
        phase3: hoursPhases[2],
        phase1Warning: phaseWarnings[0],
        phase2Warning: phaseWarnings[1],
        phase3Warning: phaseWarnings[2],
        totalHours: hoursPhases.reduce((acc, curr) => acc + curr, 0),
        present: Math.round(totalPresentHours),
        confirmed: Math.round(totalConfirmedHours),
        notConfirmed: Math.round(totalNonConfirmedHours),
        coach: `${coach.firstname} ${coach.lastname}`,
        confirmedHoursWarning: warning,
      });
    }
    this.loading = false;
  }

  getItemClass(item: any) {
    if (item.confirmedHoursWarning) {
      return "red lighten-5";
    }
  }

  exportAsCsv() {
    const header = Object.keys(this.rows[0]).join(",");
    const csv = this.rows.map((row) => Object.values(row).join(",")).join("\n");
    const blob = new Blob([header + "\n" + csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "export.csv";
    a.click();
  }
}
